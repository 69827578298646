// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-product-jsx": () => import("/Users/jonas/Repos/demo-asecom/src/templates/product.jsx" /* webpackChunkName: "component---src-templates-product-jsx" */),
  "component---src-pages-404-js": () => import("/Users/jonas/Repos/demo-asecom/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-error-jsx": () => import("/Users/jonas/Repos/demo-asecom/src/pages/error.jsx" /* webpackChunkName: "component---src-pages-error-jsx" */),
  "component---src-pages-index-jsx": () => import("/Users/jonas/Repos/demo-asecom/src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-products-jsx": () => import("/Users/jonas/Repos/demo-asecom/src/pages/products.jsx" /* webpackChunkName: "component---src-pages-products-jsx" */),
  "component---src-pages-search-jsx": () => import("/Users/jonas/Repos/demo-asecom/src/pages/search.jsx" /* webpackChunkName: "component---src-pages-search-jsx" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/Users/jonas/Repos/demo-asecom/.cache/data.json")

